import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import HomeS3Right from "../../Assets/svg/Home/HomeS3Right.svg";
import ContactUsS2Svg from "../../Assets/svg/ContactUs/ContactUsS2.svg";
import "./ContactUs.css";
import { Link } from "react-router-dom";
export default function FormWeb() {
  const formRef = useRef();
  const [captcha, setCaptcha] = useState("");
  const [userCaptchaInput, setUserCaptchaInput] = useState("");
  const [isCaptchaOpen, setIsCaptchaOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setCaptcha(`${num1} + ${num2}`);
  };

  const validateInputs = (formData) => {
    const name = formData.get("user_name").trim();
    const email = formData.get("user_email").trim();
    const message = formData.get("user_feedback").trim();

    if (name.length < 3) {
      alert("Name is required.");
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      return false;
    }

    if (message.length < 3) {
      alert("Message is required.");
      return false;
    }

    return true;
  };

  const sendEmailAfterVerification = (e) => {
    e.preventDefault();

    const form = formRef.current;
    if (!form) {
      console.error("Form not found!");
      return;
    }

    const formData = new FormData(form);

    if (!isTermsAccepted) {
      setErrorMessage("You must accept our Terms and Conditions and Privacy Policy");
      return;
    }

    if (!validateInputs(formData)) return;
    setErrorMessage(""); // Clear error message if terms are accepted
    setIsCaptchaOpen(true);
  };

  const handleTermsCheckboxChange = (event) => {
    setIsTermsAccepted(event.target.checked);
  };

  const handleSubscribeCheckboxChange = (event) => {
    setIsSubscribed(event.target.checked);
  };
  // create it at 11-3-25 to check the mail already in lists or not
  const checkEmailExists = async (email) => {
    try {
      const response = await axios.get(`https://api.brevo.com/v3/contacts/${encodeURIComponent(email)}`, {
        headers: {
          "api-key": "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",
        },
      });
      return response.status === 200;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return false;
      }
      console.error("Error checking email existence:", error);
      return null;
    }
  };

  const verifyCaptchaAndSendEmail = async () => {
    const captchaValues = captcha.split(" + ").map(Number);
    const correctCaptchaAnswer = captchaValues[0] + captchaValues[1];

    if (parseInt(userCaptchaInput) !== correctCaptchaAnswer) {
      alert("Captcha verification failed. Please try again.");
      return;
    }

    setIsLoading(true);
    try {
      const form = formRef.current;
      const formData = new FormData(form);
      const userEmail = formData.get("user_email").trim();
      const emailExists = await checkEmailExists(userEmail);
      if (emailExists) {
        alert("You are already subscribed.");
        setIsLoading(false);
      }
      const emailPayload = {
        sender: { name: formData.get("user_email"), email: "support@washyourstuff.com" },
        to: [{ email: "miyllc3@gmail.com" }],
        subject: `New Contact from ${formData.get("user_name")}`,
        htmlContent: `
        <div style="font-family: Arial, sans-serif; line-height: 1.6; color: #000;">
          <p>Hello <strong style="color: #0000FF;">WYS Team</strong>,</p>
          <p>You got a New Contact us from <strong>${formData.get("user_name")}</strong></p>
          <table style="width: 70%; border-collapse: collapse; border: 1px solid #000;">
            <tr>
              <td style="width: 30%; border: 1px solid #000; padding: 8px;"><strong>Name</strong></td>
              <td style="border: 1px solid #000; padding: 8px;"> ${formData.get("user_name")}</td>
            </tr>
            <tr>
              <td style="width: 30%; border: 1px solid #000; padding: 8px;"><strong>Email</strong></td>
              <td style="border: 1px solid #000; padding: 8px;"> ${formData.get("user_email")}</td>
            </tr>
            <tr>
              <td style="width: 30%; border: 1px solid #000; padding: 8px;"><strong>Message</strong></td>
              <td style="border: 1px solid #000; padding: 8px;"> ${formData.get("user_feedback")}</td>
            </tr>
            <tr>
              <td style="width: 30%; border: 1px solid #000; padding: 8px;"><strong>Subscribed</strong></td>
              <td style="border: 1px solid #000; padding: 8px;"> ${isSubscribed ? "Yes" : "No"}</td>
            </tr>
          </table>
          <p>Best wishes,<br><strong style="color: #0000FF;">WYS Team</strong></p>
        </div>
      `,
      };

      await axios.post("https://api.brevo.com/v3/smtp/email", emailPayload, {
        headers: {
          "api-key": "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",
          "Content-Type": "application/json",
        },
      });

      if (isSubscribed) {
        const subscriptionPayload = {
          email: formData.get("user_email"),
          attributes: { FIRSTNAME: formData.get("user_name") },
          listIds: [3],
          updateEnabled: true,
        };

        await axios.post("https://api.brevo.com/v3/contacts", subscriptionPayload, {
          headers: {
            "api-key": "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",
            "Content-Type": "application/json",
          },
        });
      }

      setIsSuccess(true);
      generateCaptcha();
      form.reset();
      setIsSubscribed(false);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        alert("Failed to subscribe. Please check your email and try again.");
        console.log(error.response);
      } else {
        alert("An unexpected error occurred during subscription. Please try again.");
      }
    } finally {
      setIsLoading(false);
      setCaptcha("");
      setUserCaptchaInput("");
    }
  };

  const enforceWordLimit = (event, maxWords) => {
    const textarea = event.target;
    const words = textarea.value.trim().split(/\s+/);
    if (words.length > maxWords) {
      textarea.value = words.slice(0, maxWords).join(" ");
      alert(`You can only enter up to ${maxWords} words.`);
    }
  };

  return (
    <>
      <form ref={formRef} onSubmit={sendEmailAfterVerification}>
        <div className="ContactUsBig">
          <div className="ContactUsS1P1">contact us</div>
          {/*  */}
          <div className="ContactUsS">
            <div className="ContactUsS1">
              <div className="ContactUsS1P2">
                <div className="ContactUsS1P2V">
                  <div className="ContactUsS1P2VFN">(*Max 1000 Character)</div>
                  {/* name */}
                  <label className="input">
                    <input className="input__field input_1" type="text" placeholder="Name *" name="user_name" required />
                  </label>
                  {/* email */}
                  <label className="input">
                    <input className="input__field input_1" type="text" placeholder="Email *" name="user_email" required />
                  </label>
                  {/* Star Rating Dropdown with react-select */}
                  <label className="input">
                    <textarea
                      className="input__field BigContactUs"
                      placeholder="Message"
                      name="user_feedback"
                      maxLength={1000}
                      onInput={(e) => enforceWordLimit(e, 1000)}
                      required
                    />
                  </label>
                </div>
              </div>
              {/* Captcha Pop-up */}
              {isCaptchaOpen && (
                <div className="captchaModal">
                  <div className="captchaModalContent">
                    {isLoading ? (
                      <p>Loading...</p>
                    ) : isSuccess ? (
                      <>
                        <p>
                          Thanks<span className="SpanBigger">,</span> your email has been sent<span className="SpanBigger">,</span> and one of our team members
                          will contact you soon.
                        </p>
                        <button
                          onClick={() => {
                            setIsCaptchaOpen(false);
                            window.location.reload();
                          }}
                        >
                          Close
                        </button>
                      </>
                    ) : (
                      <>
                        <h3>Verify you are human</h3>
                        <p>What is {captcha}?</p>
                        <input
                          type="text"
                          placeholder="Enter the answer"
                          value={userCaptchaInput}
                          onChange={(e) => setUserCaptchaInput(e.target.value)}
                          required
                        />
                        <button onClick={verifyCaptchaAndSendEmail}>Verify and Send</button>
                        <button
                          onClick={() => {
                            setIsCaptchaOpen(false);
                            setUserCaptchaInput("");
                            generateCaptcha();
                          }}
                        >
                          Cancel
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            {/*  */}
            <div className="ContactUsS2">
              <div className="ContactUsS2P1">Wash Your Stuff </div>
              <div className="ContactUsS2P2">
                {/*  */}
                <Link to="/Locations">
                  <div className="ContactUsS2P2V">
                    <img src={ContactUsS2Svg} alt="" className="ContactUsS2Svg" />
                    <div className="ContactUsS2P2VText">
                      718 Davis St<span className="SpanBigger">,</span> <br />
                      Grand Prairie<span className="SpanBigger">,</span> TX 75050<span className="SpanBigger">.</span>
                    </div>
                  </div>
                </Link>

                {/*  */}
                <Link to="/Locations">
                  <div className="ContactUsS2P2V">
                    <img src={ContactUsS2Svg} alt="" className="ContactUsS2Svg" />
                    <div className="ContactUsS2P2VText">
                      2201 Ira E Woods Ave<span className="SpanBigger">,</span> <br />
                      Grapevine<span className="SpanBigger">,</span> TX 76051<span className="SpanBigger">.</span>
                    </div>
                  </div>
                </Link>

                {/*  */}
                <Link to="/Locations">
                  <div className="ContactUsS2P2V">
                    <img src={ContactUsS2Svg} alt="" className="ContactUsS2Svg" />
                    <div className="ContactUsS2P2VText">
                      412 N Main St<span className="SpanBigger">,</span> <br />
                      Keller<span className="SpanBigger">,</span> TX 76248<span className="SpanBigger">.</span>
                    </div>
                  </div>
                </Link>
                {/*  */}
              </div>
            </div>
          </div>
          {/*  */}
          <div className="ContactSignSend">
            <div className="solutionQuestion">
              <div className="PromotionsS3T CSSCon">
                <input type="checkbox" className="PromotionsCheckBox" checked={isTermsAccepted} onChange={handleTermsCheckboxChange} />
                <label className="TermsLabel">
                  By proceeding, you consent to receive occasional emails and texts.
                  <br /> For details, please review our <span></span>
                  <Link to="/Terms-And-Conditions">Terms and Privacy Policy</Link>.
                </label>
              </div>
              {/* Error message display */}
              {errorMessage && <div className="error-message">{errorMessage}</div>}
              {/* subscribe */}
              <div className="PromotionsS3T">
                <input type="checkbox" id="subscribeCheckbox" className="PromotionsCheckBox" checked={isSubscribed} onChange={handleSubscribeCheckboxChange} />
                <label htmlFor="subscribeCheckbox">Sign up for our email list for updates, promotions, and more.</label>
              </div>
            </div>

            <input type="hidden" name="user_subscribe" value={isSubscribed ? "Yes" : "No"} />

            <input type="hidden" name="subscription_message" value={isSubscribed ? "User has subscribed to the newsletter." : "Not Avaliable"} />

            <div>
              <button className="ContactButton" type="submit">
                Send Us <img src={HomeS3Right} alt="" className="ContactButtonImg" />
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="ContactusS4">
        <div className="ContactusS4P1">Contact us by text Phone number</div>
        {/*  */}
        <div className="ContactusS4P2">817-380-8460</div>
        {/*  */}
        <div className="ContactusS4P3">
          <a href="sms:817-380-8460">
            <div className="ContactusS4P3B">
              Text us <img src={HomeS3Right} alt="" />{" "}
            </div>
          </a>
        </div>
      </div>
      {/*  */}
    </>
  );
}
